<template>
  <div class="news-detail">
    <van-nav-bar title="常见问题" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <div class="title">{{title}}</div>
      <div class="time">{{time}}</div>
      <div class="content" v-html="content"></div>
      <div class="helpful">
        <button class="info-button">是否有帮助？</button>
        <button class="success-button" @click="agree(1)">√ 是</button>
        <button class="fail-button" @click="agree(0)">× 否</button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '../../components/Footer'
export default {
  name: 'faq-detail',
  components: {
    Footer
  },
  data () {
    return {
      title: '',
      content: '',
      time: '',
      img: ''
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      let that = this
      let id = this.$route.query.id
      that.$http.get('https://api-site.huami.com/v1/faqs/' + id).then((res) => {
        that.title = res.data.title
        that.content = res.data.content
        that.time = res.data.created_at.substring(0, 16)
      }).catch((error) => {
        that.$toast.fail(error.response.data.message)
      })
    },
    agree (type) {
      let that = this
      let id = this.$route.query.id
      that.$http.post('https://api-site.huami.com/v1/faq-comments', { 'faq_id': id, 'type': type }).then((res) => {
        that.$toast.success('感谢参与')
      }).catch((error) => {
        that.$toast.fail(error.response.data.message)
      })
    }
  }
}
</script>
<style lang="scss">
.news-detail {
  .title{
    color: #333;
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    margin-top: 5vw;
    margin-bottom: 3vw
  }
  img{
    width: 100%
  }
  .time{
    font-size: 3.5vw;
    color: #666;
    text-align: center;
    margin-bottom: 3vw
  }
  .helpful{
    margin-top: 5vw
  }
  .info-button, .success-button, .fail-button {
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .info-button, .success-button, .fail-button:focus {
    outline: 0;
  }
  .info-button {
    background: #f4f4f5;
    color: #bcbec2;
  }
  .success-button {
    color: #FFF;
    background-color: #67C23A;
    border-color: #67C23A;
    margin-left: 2vw;
  }
  .fail-button {
    margin-left: 2vw;
    color: #FFF;
    background-color: #909399;
    border-color: #909399;
  }
}
</style>
